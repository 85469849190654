body {
  box-sizing: border-box;
}
.mainsection {
   position: absolute;
   top:40%;
   padding: 10px 40px;
}
h5 {
  color:#fff;
  font-weight: 500;
  font-size: 18px;
  margin: 0%;
}

.shareWrapper {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    @media (max-width: 600px) {
    justify-content: center;
    }
}


